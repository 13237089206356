<template>
  <div>
    <router-view/>
  </div>
</template>
<script setup>
import {onMounted} from 'vue'

onMounted(() => {
  console.log('当前版本为：', process.env.VUE_APP_VERSION_NUM);
});
</script>
<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.animated-left {
  position: relative;
  left: -100px; /* 初始位置在最左边 */
  opacity: 0; /* 初始透明度为0 */
  transition: all 1s ease-in-out; /* 动画持续时间为1秒，缓动函数为ease-in-out */
}

.animated-left.active {
  left: 0; /* 移动到对应位置 */
  opacity: 1; /* 透明度为1 */
}
.animated-right {
  position: relative;
  right: -100px; /* 初始位置在最左边 */
  opacity: 0; /* 初始透明度为0 */
  transition: all 1s ease-in-out; /* 动画持续时间为1秒，缓动函数为ease-in-out */
}

.animated-right.active {
  right: 0; /* 移动到对应位置 */
  opacity: 1; /* 透明度为1 */
}
</style>
